<template>
  <loader v-if="loading" />

  <section v-else id="users-list" class="route-content">
    <menu class="flex wrap">
      <!-- Filters -->
      <multi-select-list
        role="menuitem"
        class="inline"
        :filter-options="roles"
        @filters-changed="applyFilters"
      />

      <hr class="divider divider--vertical" />

      <!-- Sort -->
      <multi-select-list
        role="menuitem"
        class="inline"
        select-prompt="Sort Users by:"
        :filter-options="userFilters"
        @filters-changed="applySortFilters"
      />

      <!-- Legend -->
      <users-list-legend role="menuitem" />

      <label for="classTitle" class="flex">
        <input
          class="grow"
          v-model="searchInput"
          type="text"
          v-on:keyup="searchUsers"
          placeholder="Search users"
        />

        <button
          class="button--outline clear-filter no-shrink"
          @click.stop="searchInput = ''"
          v-on:click="searchUsers"
        >
          <i class="fas fa-times-square error--text"></i>
        </button>
      </label>
    </menu>

    <hr class="divider divider--tiny" />

    <!-- Users List -->
    <user-list-item header-item />
    <user-list-item v-for="user in filteredUsers" :key="user.id" :user="user" />
  </section>
</template>

<script>
/* eslint-disable no-undef */
import sortBy from "lodash.sortby";
import User from "../models/user.model";
import { getAllUsers, searchUsers } from "../models/user";
import Loader from "./Loader.vue";
import UserListItem from "./UserListItem.vue";
import UsersListLegend from "./UsersList.Legend.vue";
import PermissionsMixin from "./mixins/permissions.mixin";
import MultiSelectList from "./MultiSelectList.vue";

export default {
  name: "ListUsers",

  components: { Loader, UserListItem, UsersListLegend, MultiSelectList },

  mixins: [PermissionsMixin],

  data: () => ({
    error: null,
    loading: true,
    rolesFilter: new Set(),
    sortFilter: new Set(),
    users: [],
    searchInput: null
  }),

  computed: {
    filteredUsers() {
      // Update filtered users list
      const { rolesFilter, users, userId } = this;
      const showAll = rolesFilter.size === 0;
      const byUserId = ({ id }) => id !== userId;
      const byRole = u => byUserId(u) && rolesFilter.has(u.role);
      const filtered = showAll ? users.filter(byUserId) : users.filter(byRole);
      return sortBy(filtered, [...this.sortFilter]);
    },

    hiddenKeys() {
      const allKeys = ["id", ...Object.keys(User({}))];
      const visibleKeys = [
        "firstName",
        "lastName",
        "employee",
        "role",
        "username"
      ];
      return allKeys.filter(k => !visibleKeys.includes(k));
    },

    userFilters() {
      if (this.users.length === 0) return [];
      const excludeHidden = k => !this.hiddenKeys.includes(k);
      return Object.keys(this.users[0]).filter(excludeHidden);
    },

    userId() {
      const { activeUser } = this;
      return (activeUser || {}).id;
    }
  },

  async mounted() {
    document.title = `Users • All • ${APP_ORG} Admin`;
    this.startLoading("Fetching users...");
    this.users = await getAllUsers(this.role);
    this.stopLoading();
  },

  methods: {
    /**
     * Update `rolesFilter` with new object reference
     * @param {string[]} filters A list of filters to apply to `users` list
     */
    applyFilters(filters) {
      this.rolesFilter = new Set([...filters]);
    },
    /**
     * Update `sortFilter` with new object reference
     * @param {string[]} filters A list of filters to apply to `users` list
     */
    applySortFilters(filters) {
      this.sortFilter = new Set([...filters]);
    },

    /**
     * Update `sortFilter` with new object reference
     */
    async searchUsers() {
      this.searchInput.length > 0
        ? (this.users = await searchUsers(this.role, this.searchInput))
        : (this.users = await getAllUsers(this.role));
    }
  }
};
</script>

<style lang="scss">
#users-list {
  .list--users {
    box-shadow: none;
  }
}
</style>
