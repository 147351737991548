<template>
  <span v-if="loading" class="image-loader image-loader--loading" />
  <img
    v-else-if="src"
    class="image-loader--image fade-in"
    :src="src"
    :alt="alt"
  />
  <p v-else class="image-loader image-loader--error" v-text="error" />
</template>

<script>
export default {
  name: "ImageLoader",

  props: {
    alt: { type: String, required: true },
    imageUrl: { type: String, required: true }
  },

  data: () => ({ error: null, loading: true, src: null }),

  watch: {
    imageUrl: function _onImageUrl(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.preloadImage();
      }
    }
  },

  mounted() {
    // preload image
    this.preloadImage();
  },

  methods: {
    onImageError() {
      this.error = "Invalid image URL";
      this.loading = false;
      this.$emit("image-error");
    },

    onImageLoad() {
      this.src = this.imageUrl;
      this.loading = false;
      this.$emit("image-loaded");
    },

    preloadImage() {
      const { imageUrl } = this;
      const image = new Image(1, 1);
      image.onload = this.onImageLoad;
      image.onerror = this.onImageError;
      image.src = imageUrl;
    }
  }
};
</script>

<style lang="scss" scoped>
.image-loader {
  align-self: stretch;

  &--error {
    @include flex-center;
    @include heading-font;
    color: $grey;
    font-weight: bold;
  }

  &--image {
    align-self: flex-start;
  }

  &--loading {
    @include after-spinner($translucent, $md);
  }
}
</style>
